<template>
  <CommentCardView>
    <template v-slot:title>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          Просмотр тех. карты
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:actions>
      <v-card-actions>
        <v-btn color="default" @click="$router.go(-1)">назад</v-btn>
      </v-card-actions>
    </template>
  </CommentCardView>
</template>

<script>
import CommentCardView from './components/CommentCardView.vue'

export default {
  components: { CommentCardView },
}
</script>
